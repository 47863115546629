import * as React from "react";
import styled, { css } from "styled-components";
import { MaxWidth, maxMedia } from "../utils/style";
import Carousel from "nuka-carousel";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { TiltImage } from "./tilt-image";
import { StaticQuery, graphql, useStaticQuery, Link } from "gatsby";
import RTF from "./rtf";
import { DonwloadButton } from "./presse-kontakt";
import { EasyGrid } from "./styles/EasyGrid";

const BookElementDiv = styled.div`
  width: 100%;
  padding: 3rem 5rem 5rem 5rem;
  /* height: 300px; */
  display: flex;

  ${maxMedia.tablet`
    padding: 1rem 2rem 2rem 2rem;
    flex-direction: column;
    > .img {
      margin-left: 0 !important;
      margin-top: 3rem;
    }
  `}

  align-items: center;

  > .img {
    margin-left: 3rem;

    > * {
      width: 200px;
    }
  }
`;

export const BookElement = ({ data }: { data: any }) => {
  return (
    <BookElementDiv>
      <div className="description">
        <RTF content={data.description.json} />
      </div>
      <div className="img">
        <TiltImage fluid={data.coverImage.fluid} />
      </div>
    </BookElementDiv>
  );
};

const BooksCarouselDiv = styled.div``;
const BooksCarouselButton = styled.div`
  font-size: 2rem;
  color: #555;
`;

export const BooksCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBook(sort: { fields: order, order: ASC }) {
        nodes {
          id
          description {
            json
          }
          coverImage {
            fluid(maxWidth: 450) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <MaxWidth>
        <h2 style={{ textAlign: "center", marginTop: "3rem" }}>Meine Bücher</h2>

        <BooksCarouselDiv>
          <Carousel
            // wrapAround={true}
            // autoplay={true}
            // easing="easeQuadInOut"
            // edgeEasing="easeQuadInOut"
            renderCenterLeftControls={({ previousSlide }) => (
              <BooksCarouselButton onClick={previousSlide}>
                <MdKeyboardArrowLeft />
              </BooksCarouselButton>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <BooksCarouselButton onClick={nextSlide}>
                <MdKeyboardArrowRight />
              </BooksCarouselButton>
            )}
          >
            {data.allContentfulBook.nodes.map(x => (
              <BookElement data={x} />
            ))}
          </Carousel>

          {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "3rem",
            marginTop: "3rem"
          }}
        >
          <h3> Bücher können Sie direkt hier bestellen</h3>
          <DonwloadButton
            icon="book"
            text="Zur Bestellliste"
            component={Link}
            to={"/bestellliste"}
          />
        </div> */}
        </BooksCarouselDiv>
      </MaxWidth>

      <BookOrder />
    </>
  );
};

const BookOrder = styled(props => {
  return (
    <MaxWidth greyGradient>
      <div {...props}>
        <Link to="bestellliste">
          → Bücher können Sie hier <b>direkt bestellen</b>
        </Link>
        <div>
          <DonwloadButton
            icon="book"
            text="Zur Bestellliste"
            component={Link}
            to={"/bestellliste"}
          />
        </div>
      </div>
    </MaxWidth>
  );
})`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${maxMedia.phone`
    flex-direction: column-reverse;
    > * {
      margin: 1rem 0;
    }
  `}

  > *:nth-child(1) {
    margin-right: 3rem;
  }

  > *:nth-child(2) {
  }
`;
