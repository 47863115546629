import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import { ImgWithText } from "./coach-trainer-redner";
import { MaxWidth, minMedia, TABLET_BR } from "../utils/style";
import { Link } from "gatsby";
import { useWindowSize } from "../utils/window-size";
import { picFilter } from "../utils/const";
import { slugify } from "../utils/slugify";

interface Props {}

const Div = styled.div`
  min-height: 500px;
  /* background-attachment: fixed; */
  background-image: url(${require("../images/bg_pattern_2x.png")});
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4rem;
  justify-content: center;

  > * {
    display: block;
    padding: 0.25rem;
    margin-top: 0.5rem;
  }

  ${minMedia.tablet`
    padding: 2rem 4rem 9rem 2rem;
    
    > *:nth-child(even) {
      position: relative;
      top: 5rem;
    }
  `}
`;

export const Kompetenzen = (props: Props) => {
  let size = useWindowSize();

  const data = useStaticQuery(graphql`
    {
      allContentfulPodcastArticleCategory {
        nodes {
          title
          image {
            fluid(maxWidth: 700, maxHeight: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const orderedCategories = _.sortBy(
    data.allContentfulPodcastArticleCategory.nodes,
    (x, idx) =>
      ["Digitalisierung", "Resilienz", "Führung", "Innovation"].indexOf(x.title)
  );

  return (
    <Div id="meine-kompetenzen">
      <MaxWidth>
        <h2 style={{ textAlign: "center", marginTop: "5rem" }}>
          Meine Kompetenzen
        </h2>

        <Inner>
          {orderedCategories.map(x => {
            const slug = slugify(x.title);

            return (
              <Link key={slug} to={`/meine-kompetenzen/${slug}`}>
                <ImgWithText
                  fluid={x.image.fluid}
                  style={{
                    //   boxShadow: "0 10px 25px -10px rgba(0,0,0, 0.8)",
                    borderRadius: "50%",
                    width: size.width > TABLET_BR ? 400 : 300,
                    maxWidth: "100%"
                  }}
                  imageStyle={{
                    maxWidth: "100%",
                    filter: picFilter
                  }}
                >
                  <h2 style={{ color: "white", marginBottom: 0 }}>{x.title}</h2>
                </ImgWithText>
              </Link>
            );
          })}
        </Inner>
      </MaxWidth>
    </Div>
  );
};
