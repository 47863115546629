import * as React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img, { GatsbyImageProps } from "gatsby-image";
import {
  MaxWidth,
  fillAbs,
  centerFlex,
  READ_WIDTH,
  maxMedia
} from "../utils/style";
import RTF from "./rtf";
import { TiltImage } from "./tilt-image";
import { picFilter } from "../utils/const";

interface ImgWithTextProps extends GatsbyImageProps {
  children: any;
  imageStyle?: React.CSSProperties;
}

export const ImgWithTextDiv = styled.div`
  position: relative;
  > .text-overlay {
    pointer-events: none;
    ${fillAbs}
    ${centerFlex}
  }

  > .img {
    transform: translateZ(-100px);
  }
`;
export const ImgWithText = ({ children, ...otherProps }: ImgWithTextProps) => {
  return (
    <ImgWithTextDiv style={otherProps.style}>
      <div className="img">
        <TiltImage
          {...otherProps}
          style={{
            //   boxShadow: "0 10px 25px -10px rgba(0,0,0, 0.8)",
            ...otherProps.imageStyle,
            borderRadius: "50%"
          }}
        />
      </div>
      <div className="text-overlay">{children}</div>
    </ImgWithTextDiv>
  );
};

export const CoachTrainerRednerItem = styled(({ className, data }: any) => {
  return (
    <div className={className}>
      <div className="img">
        <ImgWithText
          style={{ width: 250 }}
          fluid={data.image.fluid}
          imageStyle={{ filter: picFilter }}
        >
          <h3 style={{ color: "white", marginBottom: 0 }}>{data.title}</h3>
        </ImgWithText>
      </div>
      <div className="description">
        <RTF content={data.description ? data.description.json : "..."} />
      </div>
    </div>
  );
})`
  display: flex;
  align-items: center;

  ${maxMedia.tablet`
    flex-direction: column;

    > .img {
      margin-right: 0 !important;
      margin-bottom: 3rem;
    }
  `}

  > .img {
    // min-width: 220px;
    margin-right: 3rem;
  }

  :not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cecece;
  }
`;

export const CoachTrainerRedner = () => {
  return (
    <MaxWidth greyGradient maxWidth={READ_WIDTH + 200}>
      <div style={{ padding: "3rem 1rem" }}>
        <StaticQuery
          query={graphql`
            query {
              allContentfulCoachTrainerRedner (sort: { fields: order, order: ASC }) {
                nodes {
                  title
                  description {
                    json
                  }
                  image {
                    fluid(maxWidth: 250, maxHeight: 250) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
              }
            }
          `}
          render={data =>
            data.allContentfulCoachTrainerRedner.nodes.map(x => (
              <CoachTrainerRednerItem key={x.id} data={x} />
            ))
          }
        />
      </div>
    </MaxWidth>
  );
};
