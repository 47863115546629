import * as React from 'react';
import styled from 'styled-components';
import { maxMedia, minMedia } from '../../utils/style';

export const Div = styled.div`
  display: flex;
  justify-content: center;

  ${maxMedia.tablet`
    flex-direction: column;
    > .right {
      margin-top: 3rem;
    }
  `}

  ${minMedia.tablet`
    flex-direction: row;
    align-items: baseline;
    > *:not(:last-child) {
      margin-right: 5rem;
    }

    > .left {
      flex: 1 1 50%;
    }

    > .right {
      flex: 1 1 50%;
    }
  `}
`;

export const TwoCol = (props: any) => {
  return <Div {...props}>
      {props.children}
  </Div>;
};
