import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { AktuellerPodcast } from "../components/aktueller-podcast";
import { BooksCarousel } from "../components/books-carousel";
import { CoachTrainerRedner } from "../components/coach-trainer-redner";
import { ContactForm } from "../components/contact-form";
import { Header } from "../components/header";
import { Kompetenzen } from "../components/kompetenzen";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { EasyGrid } from "../components/styles/EasyGrid";
import { LinkButton } from "../components/styles/LinkButton";
import { TABLET_BR, MaxWidth, maxMedia } from "../utils/style";
import { useWindowSize } from "../utils/window-size";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import { TwoCol } from "../components/styles/TwoCol";

const IndexPage = () => {
  let size = useWindowSize();

  const header = (
    <>
      <h4>Ziele erreichen mit</h4>
      <h1>Winfried Neun</h1>
      <h4 style={{ marginTop: "4rem" }}>
        Wirtschaftspsychologe und Verhaltensökonom
      </h4>
    </>
  );

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "WNroundSW.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="" keywords={[`gatsby`, `application`, `react`]} />
      <Header>
        {size.width > TABLET_BR ? (
          <>
            <div className="circle-text">{header}</div>
            <GatsbyImage
              className="circle"
              fluid={data.file.childImageSharp.fluid}
            />
          </>
        ) : (
          <>
            <div className="circle-text">{header}</div>
            <div
              className="circle"
              style={{
                backgroundPosition: "50% top",
                backgroundImage: `url(${require("../images/wn_mobile.png")})`,
              }}
            />
          </>
        )}
      </Header>

      <AktuellerPodcast />
      <NeunsightAndBlog />
      <CoachTrainerRedner />
      <BooksCarousel />
      <Kompetenzen />
      <ContactForm />
    </Layout>
  );
};

export default IndexPage;

const NeunsightAndBlog = () => {
  return (
    <MaxWidth
      greyGradient
      style={{ background: "#e6e6e6", paddingTop: "1rem" }}
    >
      <TwoCol>
        <Neunsight>
          <a href="http://neunsight.de" target="_blank">
            → Zu meinem <b>Fachmagazin</b>
          </a>
          <div style={{ marginTop: "4rem" }}>
            <a href="http://neunsight.de" target="_blank">
              <img
                width={300}
                src={require("../images/neunsight.png")}
                alt=""
              />
            </a>
          </div>
        </Neunsight>
        <Neunsight>
          <Link to="blog">
            → Zu meinem <b>Blog</b>
          </Link>
          <div style={{ marginTop: "1rem" }}>
            <Link to="blog">
              <img
                width={300}
                src={require("../images/blog-logo.png")}
                alt=""
              />
            </Link>
          </div>
        </Neunsight>
        <Neunsight>
          <a href="https://speakerneun.de/medien/" target="_blank">
            → Zu meinem <b>Medienarchiv</b>
          </a>
          <div style={{ marginTop: "1rem" }}>
            <a href="https://speakerneun.de/medien/" target="_blank">
              <img
                width={170}
                src={require("../images/monitor-screen.png")}
                alt=""
              />
            </a>
          </div>
        </Neunsight>
      </TwoCol>
    </MaxWidth>
  );
};

const Neunsight = styled((props) => {
  return <div {...props}>{props.children}</div>;
})`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  > *:first-child {
    margin-bottom: 1rem;
  }
`;
